import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import Layout, { ThreeCols } from "../components/layout"
import {
  Button,
  NeedHelp,
  Avantages,
  Spacer,
  Title,
  Input,
} from "../components/banners"
import { createStripeSession } from "../actions"
import { useForm } from "../hooks"
import { Submitter } from "../components/submitter"

const Form = () => {
  const member = useSelector(state => state.member)
  const cart = useSelector(state => state.cart)
  const validate = () => {}
  const pay = () => createStripeSession(member, cart)
  const [_, submit, errors] = useForm(validate, pay)

  if (!member || !cart) {
      return <div></div>
  }
  const price = cart.totalBeforeShipping + cart.shippingFees[member.shipping.option]

  

  return (
    <ThreeCols shippingOption={member.shipping.option}>
        <div style={{ border: "1px solid black", padding: "15px 30px" }}>
    <Title>Paiement</Title>
    <Submitter error={errors.top} action={submit} style={{width: '100%'}}>Payer {price} €</Submitter>
    </div>
    </ThreeCols>
  )
}

export default ({ location }) => {
  return (
    <Layout>
      <Form />
      <Spacer v="6rem" />
      <Avantages />
    </Layout>
  )
}
